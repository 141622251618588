<template>
	<div>
		<a-modal title="选择商品规格" :width="480" :visible="visible" @cancel="handleCancel" >
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="choose-sku-goods flex" v-if="type=='normal'">
						<div class="flex">
							<img v-if="goods.cover_img != null" :src="goods.cover_img"/>
							<div class="ml10">
								<div class="ft14 ftw400 cl-black text-over4">{{goods.name}}</div>
								<div class="ft12 ftw400 cl-theme mt5 " v-if="form.sku_index != -1">会员价格：¥{{goods.sku[form.sku_index].vip_price}}</div>
								<div class="ft12 ftw400 cl-theme mt5 " v-else>会员价格：¥{{goods.vip_price}}</div>
								<div class="flex alcenter ft12 ftw400 cl-notice">
									<div>门市原价：</div>
									<div style="text-decoration: line-through;" v-if="form.sku_index != -1">¥{{goods.sku[form.sku_index].original_price}}</div>
									<div style="text-decoration: line-through;" v-else>¥{{goods.original_price}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="choose-sku-goods flex" v-if="type=='pre_sale'">
						<div class="flex">
							<img v-if="goods.cover_img != null" :src="goods.cover_img"/>
							<div class="ml10">
								<div class="ft14 ftw400 cl-black text-over4">{{goods.name}}</div>
								<!-- <div class="ft12 ftw400 cl-theme mt5 ">预售价格：¥{{goods.yu_price}}</div> -->
								<div class="ft12 ftw400 cl-theme mt5 " v-if="form.sku_index != -1">预售价格：¥{{goods.sku[form.sku_index].yu_price}}</div>
								<div class="ft12 ftw400 cl-theme mt5 " v-else>预售价格：¥{{goods.yu_price}}</div>
								<div class="flex alcenter ft12 ftw400 cl-notice">
									<div>门市原价：</div>
									<div style="text-decoration: line-through;" v-if="form.sku_index != -1">¥{{goods.sku[form.sku_index].original_price}}</div>
									<div style="text-decoration: line-through;" v-else>¥{{goods.original_price}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="choose-sku-goods flex" v-if="type=='store'">
						<div class="flex">
							<img v-if="goods.goods.cover_img != null" :src="goods.goods.cover_img"/>
							<div class="ml10">
								<div class="ft14 ftw400 cl-black text-over4">{{goods.goods.name}}</div>
								<div class="ft12 ftw400 cl-theme mt5 ">囤货总价：¥{{goods.total_price}}</div>
								<div class="flex alcenter ft12 ftw400 cl-notice">
									<div>囤货价值：</div>
									<div style="text-decoration: line-through;">¥{{goods.worth}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="mt24" v-if="goods.is_have_sku == 1">
						<div class="ft14 ftw500 cl-info">选择规格</div>
						<div class="mt16">
							<div class="flex wrap">
								<template v-for="(item,index) in goods.sku">
									<div class="choose-sku-item active" v-if="index==form.sku_index">{{item.name}}</div>
									<div class="choose-sku-item " v-else @click="chooseSkuAct(index)">{{item.name}}</div>
								</template>
							</div>
						</div>
					</div>
					
					<div class="mt24" v-if="(!goods.is_have_sku && !goods.sku) || goods.is_have_sku==0 || (goods.is_have_sku==1 && form.sku_index > -1)">
						<div class="flex space">
							<div class="ft14 ftw500 cl-info">选择数量</div>
							<div class="flex alcenter">
								<i class="iconfont iconminus_small ft20 clickAct cl-notice" v-if="this.type=='store' || form.num<=1" />
								<i class="iconfont iconminus_small ft20 clickAct cl-theme" v-else @click="reduce()"/>
								<div class="ml20 mr20 ft16 ftw400 cl-main">{{form.num}}</div>
								<i class="iconfont iconadd_small1 ft20 clickAct cl-notice" v-if="this.type=='store' || form.num>=maxNum"  />
								<i class="iconfont iconadd_small1 ft20 clickAct cl-theme" v-else  @click="plus()"/>
							</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			value:{
				type:Array,
				default:function(){
					return new Array
				}
			},
			goods:{
				type:Object,
				default:function(){
					return new Object
				}
			},
			type:{
				type:String,
				default:'',
			},
		},
	
		data() {
			return {
				loading: false,
				confirmLoading: false,
				select_ids_data:[],
				form:{
					sku_index:-1,
					num:0,
				}
			}
		},
		
		created() {
			for(var i in this.value){
				this.select_ids_data.push(this.value[i]);
			}
			if(this.type=='store'){
				this.form.num=1;
			}
		},
		computed:{
			maxNum(){
				if(this.goods.is_have_sku==1){
					if(this.form.sku_index>-1){
						return this.goods.sku[this.form.sku_index].quota;
					}
				}
				return this.goods.quota;
			}
		},
		
		methods: {
			chooseSkuAct(index){
				this.form.sku_index=index;
				this.form.num=0;
			},
			
			reduce(){
				if(this.type=='store'){
					this.form.num = this.form.num - this.goods.num;
				}else{
					this.form.num = this.form.num - 1;
				}
				
			},
			
			plus(){
				if(this.type=='store'){
					this.form.num = this.form.num + this.goods.num;
				}else{
					this.form.num = this.form.num + 1;
				}
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			onSubmit(){
				if(this.form.num<=0){
					this.$message.error('请输入数量');
					return false;
				}
				this.select_ids_data.push({
					goods_id:this.type=='store' ? this.goods.store_goods_id : this.goods.goods_id,
					sku_id:this.form.sku_index>-1 ? this.goods.sku[this.form.sku_index].sku_id : 0,
					num:this.form.num
				});
				let value = this.select_ids_data;
				this.$emit('input',value);
				this.$emit("ok");
			}
		}
	}
</script>

<style>
	.choose-sku-goods{
		width: 420px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
	}
	
	.choose-sku-goods img{
		width: 60px;
		height: 60px;
	}
	
	.choose-sku-item{
		padding: 8px 12px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		font-size: 12px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
		margin-bottom: 10px;
		cursor: pointer;
	}
	
	.choose-sku-item.active{
		padding: 8px 12px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	
</style>
