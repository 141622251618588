<template>
	<div>
		<a-modal title="选择商品" :width="480" :visible="visible" @cancel="handleCancel" >
			<template slot="footer" v-if="type != 'store'">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定
				</a-button>
			</template>
			
			<template slot="footer" v-else>
				<a-button key="back" @click="handleCancel">
					知道了
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex center">
						<a-input-search placeholder="搜索商品名称或扫码商品条形码" v-model="search.name" style="width: 420px" @search="onSearch" />
					</div>
					
					<div class="mt16">
						<div class="flex wrap center" v-if="getData.length>0">
							<div class="choose-goods-it-item flex space" v-for="(item,index) in getData">
								<div class="flex" v-if="type=='normal'">
									<img v-if="item.cover_img != null" :src="item.cover_img"/>
									<div class="ml10">
										<div class="ft14 ftw400 cl-black text-over4">{{item.name}}</div>
										<div class="ft12 ftw400 cl-theme mt5 ">会员价格：¥{{item.vip_price}}</div>
										<div class="flex alcenter ft12 ftw400 cl-notice">
											<div>门市原价：</div>
											<div style="text-decoration: line-through;">¥{{item.original_price}}</div>
											<template v-if="item.is_select==1 && item.sku_id>0">
												<template v-for="(sku_item,sku_index) in item.sku">
													<div class="ml10" v-if="sku_item.sku_id == item.sku_id" >已选规格：{{sku_item.name}}</div>
												</template>
												
											</template>
										</div>
										
									</div>
								</div>
								
								<div class="flex" v-if="type=='pre_sale'">
									<img v-if="item.cover_img != null" :src="item.cover_img"/>
									<div class="ml10">
										<div class="ft14 ftw400 cl-black text-over4">{{item.name}}</div>
										<div class="ft12 ftw400 cl-theme mt5 ">预售价格：¥{{item.yu_price}}</div>
										<div class="flex alcenter ft12 ftw400 cl-notice">
											<div>门市原价：</div>
											<div style="text-decoration: line-through;">¥{{item.original_price}}</div>
											<template v-if="item.is_select==1 && item.sku_id>0">
												<template v-for="(sku_item,sku_index) in item.sku">
													<div class="ml10" v-if="sku_item.sku_id == item.sku_id" >已选规格：{{sku_item.name}}</div>
												</template>
												
											</template>
										</div>
										
									</div>
								</div>
								
								<div class="flex" v-if="type=='store'">
									<img v-if="item.goods.cover_img != null" :src="item.goods.cover_img"/>
									<div class="ml10">
										<div class="ft14 ftw400 cl-black text-over4">{{item.goods.name}}</div>
										<div class="ft12 ftw400 cl-theme mt5 ">囤货总价：¥{{item.total_price}}</div>
										<div class="flex alcenter ft12 ftw400 cl-notice">
											<div>囤货价值：</div>
											<div style="text-decoration: line-through;">¥{{item.worth}}</div>
										</div>
									</div>
								</div>
								
								<div class="flex alcenter" v-if="item.is_select==1 && type != 'store'">
									<i class="iconfont iconminus_small ft24 clickAct cl-notice" v-if="item.num<=1" />
									<i class="iconfont iconminus_small ft24 clickAct cl-theme" v-else @click="reduce(index)"/>
									<div class="ml10 mr10 ft14 ftwblod cl-main">{{item.num}}</div>
									<i class="iconfont iconadd_small1 ft24 clickAct cl-theme"  @click="plus(index)"/>
								</div>
								
								
								
								<div class="flex alcenter" v-else-if="item.is_select==1 && type == 'store'">
									<!-- <i class="iconfont iconminus_small ft24 clickAct cl-notice"  />
									
									<div class="ml10 mr10 ft14 ftwblod cl-main">{{item.num}}</div>
									<i class="iconfont iconadd_small1 ft24 clickAct cl-notice"  /> -->
									<div class="choose-goods-it-item-duihao  flex center alcenter cl-theme">
										<i class="iconfont iconduihao ft12"/>
									</div>
								</div>
								
								<div class="flex alcenter" v-else-if="type!='store'">
									<i class="iconfont iconadd_small1 ft24 clickAct cl-theme"  @click="chooseSku(index)"/>
								</div>
								
								<div v-else class="flex alcenter">
									<!-- <i class="iconfont iconadd_small1 ft24 clickAct cl-notice"  /> -->
									<div class="choose-goods-it-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
										选择
									</div>
								</div>
								
								
								
								
							</div>
						</div>
						
						<div v-else class="flex center mt20">
							<a-empty/>
						</div>
					</div>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
					
					<div v-if="chooseSkuVisible">
						<choose-sku v-model="select_ids_data" :type="type" :goods="goods" :visible="chooseSkuVisible" @cancel="cancelChooseSku" @ok="okChooseSku"></choose-sku>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	import chooseSku from './chooseGoods/chooseSku.vue';
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			type:{
				type:String,
				default:'',
			},
			value:{
				type:Array,
				default:function(){
					return new Array
				}
			}
		},
		mixins:[listMixin],
		components:{
			chooseSku,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				chooseSkuVisible:false,
				select_ids_data:[],
				goods:{},
				pagination:{
					current:1,
					pageSize:4,
					total:0,
				},
				search:{
					name:'',
				},
				datas:[],
				
			}
		},
		created() {
			for(var i in this.value){
				this.select_ids_data.push(this.value[i]);
			}
			this.getLists();
		},
		
		computed:{
		
			getData(){
				let datas =JSON.parse(JSON.stringify(this.datas));
				for(var i in datas){
					for(var j in this.select_ids_data){
						let status = ((this.type=='store' && datas[i].store_goods_id==this.select_ids_data[j].goods_id) || (this.type!='store' && datas[i].goods_id==this.select_ids_data[j].goods_id))
						if(status){
							datas[i].is_select=1;
							datas[i].select_index=j;
							datas[i].num=this.select_ids_data[j].num;
							datas[i].sku_id=this.select_ids_data[j].sku_id;
						}
					}
				}
				console.log(datas);
				return datas;
			}
		},
		
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getGoodsFromFakeOrder',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					type:this.type,
					name:this.search.name,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			onSearch(value){
				this.page=1;
				this.getLists()
			},
			
			checkIt(index){
				this.select_ids_data=new Array;
				this.select_ids_data.push({
					goods_id:this.datas[index].store_goods_id,
					sku_id:0,
					num:1
				});
				
				this.onSubmit();
			},
			
			chooseSku(index){
				this.goods=this.getData[index];
				this.chooseSkuVisible=true;
			},
			cancelChooseSku(){
				this.chooseSkuVisible=false;
			},
			okChooseSku(){
				this.chooseSkuVisible=false;
			},
			
			reduce(index){
				let select_index=this.getData[index].select_index;
				this.select_ids_data[select_index].num=this.select_ids_data[select_index].num-1;
			},
			
			plus(index){
				let select_index=this.getData[index].select_index;
				this.select_ids_data[select_index].num=this.select_ids_data[select_index].num+1;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			
			onSubmit(){
				if(this.select_ids_data.length<=0){
					this.$message.error('请选择商品');
					return false;
				}
				let value = this.select_ids_data;
				this.$emit('input',value);
				this.$emit("ok");
			},
		}
	}
</script>

<style>
	.choose-goods-it-item{
		width: 540px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		margin-bottom: 10px;
		padding: 10px;
	}
	
	.choose-goods-it-item img{
		width: 60px;
		height: 60px;
	}
	
	.choose-goods-it-item-duihao{
		width: 60px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
	}
	.choose-goods-it-item-select{
		width: 60px;
		height: 32px;
		background: #4772FF;
		border-radius: 4px;
		cursor: pointer;
	}
	
	
</style>
